@import "../../styles/theme.scss";

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: var(--icabbi-accent-color);
  --rdp-background-color: var(--icabbi-background-color);
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

.rdp-day_range_middle {
  color: $i-day-grey-0;
  background-color: var(--icabbi-background-color);
}

.rdp:not([dir="rtl"]) {
  .rdp-cell:has(.rdp-day_range_start:not(.rdp-day_range_end)) {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--icabbi-background-color);
      border-radius: 50% 0 0 50%;
    }
  }

  .rdp-day_range_start:not(.rdp-day_range_end) {
    position: relative;
    z-index: 1;
    border-radius: 50%;
  }

  .rdp-cell:has(.rdp-day_range_end:not(.rdp-day_range_start)) {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--icabbi-background-color);
      border-radius: 0 50% 50% 0;
    }
  }

  .rdp-day_range_end:not(.rdp-day_range_start) {
    position: relative;
    z-index: 1;
    border-radius: 50%;
  }
}
