@use "../../../styles/colors.scss" as color;
@use "../../../styles/mixins.scss" as mixin;
@import "../../../styles/theme.scss";

.custom-checkbox {
  &:hover {
    background-color: $i-day-grey-5;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  &:hover .ant-checkbox-inner {
    border-color: color.$primary-color;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: color.$primary-color;
      border-color: color.$primary-color;
    }
  }
  .ant-checkbox-checked:after {
    border-color: $i-day-grey-0;
  }
}
