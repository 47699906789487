@use "src/styles/colors" as color;
@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 10px 22px 12px 4px; // padding-right compensates loader icon width

  color: $i-day-primary-white;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 1px;
}

.unsetLetterSpacing {
  letter-spacing: unset;
}

.primary {
  transition: all 0.1s;

  &.enabled {
    background: $i-day-primary-green;
    border: 1px solid $i-day-primary-green;

    cursor: pointer;

    &:hover,
    &:focus {
      outline: 0;
      color: $i-day-primary-white;
      background: $i-day-primary-green-darker;
    }

    &:active {
      outline: 0;
      color: $i-day-primary-white;
      background: $i-day-primary-green-darkest;
    }
  }

  &.warning {
    background: $i-day-primary-yellow;
    border-color: $i-day-primary-yellow;

    &:hover,
    &:focus {
      background: $i-day-primary-yellow-darker;
      border-color: $i-day-primary-yellow-darker;
    }

    &:active {
      background: $i-day-primary-yellow-darkest;
      border-color: $i-day-primary-yellow-darkest;
    }
  }

  &.error {
    background: $i-day-primary-red;
    border-color: $i-day-primary-red;

    &:hover,
    &:focus {
      background: $i-day-primary-red-darker;
      border-color: $i-day-primary-red-darker;
    }

    &:active {
      background: $i-day-primary-red-darkest;
      border-color: $i-day-primary-red-darkest;
    }
  }

  &.disabled {
    color: $i-day-primary-white;
    background: $i-day-grey-4;
    border: 1px solid $i-day-grey-4;
    cursor: not-allowed;
  }
}

.default {
  background: transparent;
  transition: all 0.1s;

  &.enabled {
    color: $i-day-primary-green;
    border: 1px solid $i-day-primary-green;
    justify-content: center;
    text-align: center;

    cursor: pointer;

    &:hover,
    &:focus {
      color: $i-day-primary-green-darker;
      border-color: $i-day-primary-green-darker;
    }

    &:active {
      color: $i-day-primary-green-darkest;
      border-color: $i-day-primary-green-darkest;
    }
  }

  &.warning {
    color: $i-day-primary-yellow;
    border-color: $i-day-primary-yellow;

    &:hover,
    &:focus {
      color: $i-day-primary-yellow-darker;
      border-color: $i-day-primary-yellow-darker;
    }

    &:active {
      color: $i-day-primary-yellow-darkest;
      border-color: $i-day-primary-yellow-darkest;
    }
  }

  &.error {
    color: $i-day-primary-red;
    border-color: $i-day-primary-red;

    &:hover,
    &:focus {
      color: $i-day-primary-red-darker;
      border-color: $i-day-primary-red-darker;
    }

    &:active {
      color: $i-day-primary-red-darkest;
      border-color: $i-day-primary-red-darkest;
    }
  }

  &.disabled {
    color: $i-day-grey-4;
    border: 1px solid $i-day-grey-4;
    cursor: not-allowed;
  }
}

.link {
  padding: 0;
  background: transparent;
  transition: all 0.1s;
  text-decoration: underline;
  border: 0;

  &.enabled {
    color: $i-day-primary-green;

    cursor: pointer;

    &:hover,
    &:focus {
      color: $i-day-primary-green-darker;
      text-decoration: none;
    }

    &:active {
      color: $i-day-primary-green-darkest;
      text-decoration: none;
    }
  }

  &.warning {
    color: $i-day-primary-yellow;

    &:hover,
    &:focus {
      color: $i-day-primary-yellow-darker;
      text-decoration: none;
    }

    &:active {
      color: $i-day-primary-yellow-darkest;
      text-decoration: none;
    }
  }

  &.error {
    color: $i-day-primary-red;

    &:hover,
    &:focus {
      color: $i-day-primary-red-darker;
      text-decoration: none;
    }

    &:active {
      color: $i-day-primary-red-darkest;
      text-decoration: none;
    }
  }

  &.disabled {
    color: $i-day-grey-1;
    cursor: not-allowed;
  }
}

.icon {
  width: 40px;
  height: 40px;

  border: none;
  padding: 0;
  justify-content: center;

  color: $i-day-grey-1;
  background: transparent;
  transition: all 0.1s;

  &.enabled {
    cursor: pointer;

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.1);
    }

    &:active {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &.disabled {
    cursor: not-allowed;
    background: $i-day-grey-4;
  }
}

.shapeRound {
  /**
   * reference: https://github.com/tailwindlabs/tailwindcss/discussions/3373
   */
  border-radius: 9999px;
}

.shapeRounded {
  border-radius: 6px;
}

.displayBlock {
  display: flex;
  justify-content: center;
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}

.fontSm {
  font-size: 13px;
}

.fontMd {
  font-size: 15px;
}

.fontL {
  font-size: 18px;
}

.fontLight {
  font-weight: 300;
}

.fontNormal {
  font-weight: 400;
}

.fontBold {
  font-weight: 700;
}

.loaderIconWrapper {
  display: flex;
  padding-right: 4px;
}

.loaderIcon {
  width: 14px;
  height: 14px;
  visibility: hidden;
  opacity: 0;

  will-change: transform;

  &.visible {
    animation: rotating 1s linear infinite;
    visibility: visible;
    opacity: 1;
  }
}

@keyframes rotating {
  100% {
    rotate: 1turn;
  }
}
