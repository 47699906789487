@import "../../styles/theme.scss";

$horizontal-padding: $space-2;
$vertical-padding: $space-2-alternative;
$icon-width: $space-3-alternative;

$triangle-color: $i-day-grey-2;

$triangle-width: 5px;

.wrapper {
  position: relative;
}

.select {
  width: 100%;
  border: 1px solid $i-day-grey-4;
  border-radius: 6px;
  padding: $vertical-padding $horizontal-padding + $triangle-width * 2
    $vertical-padding $horizontal-padding;

  color: $i-day-grey-0;
  font-size: 15px;
  line-height: 21px;

  background-color: $i-day-grey-8;

  appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, $triangle-color 50%),
    linear-gradient(135deg, $triangle-color 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 4px),
    calc(100% - 15px) calc(1em + 4px);
  background-size:
    $triangle-width $triangle-width,
    $triangle-width $triangle-width;
  background-repeat: no-repeat;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.hasIcon {
    padding-left: $horizontal-padding + $icon-width + $vertical-padding;
  }
}

.icon {
  position: absolute;

  top: 0;
  bottom: 0;
  left: $horizontal-padding;
  width: $icon-width;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;

  pointer-events: none;
}
