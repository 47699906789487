.stripe-alert-banner {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;

  & .ant-tag {
    margin-right: 0;
  }

  & svg {
    margin-top: 12px;

    @media (max-width: 894px) {
      display: none;
    }
  }
}
