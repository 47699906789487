@import "../../styles/theme.scss";

.passwordInputWrapper {
  display: flex;
  flex-direction: column;
}
.passwordInputInner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.passwordInput {
  width: 100%;

  & input {
    &:hover {
      border-color: $i-day-grey-0 !important;
      outline: $i-day-grey-0 !important;
    }
    &:focus {
      border-color: $i-day-grey-0 !important;
      outline: $i-day-grey-0 !important;
      box-shadow: 0 0 0 2px rgba(136, 136, 136, 0.2) !important;
    }
    @media (min-width: 576px) {
      height: 48px;
    }
    padding-right: 40px;
  }
}

.eyeIcon {
  position: absolute;
  top: 50%;
  right: 12px;
  @media (max-width: 576px) {
    right: 16px;
  }
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
}
