@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.driver-booking-wrapper {
  .loading-logo {
    min-height: 400px;
  }
  .sub-top-wrapper {
    background-color: $i-day-grey-7;
    padding-left: 20px;
    h1 {
      font-size: 20px;
    }
  }
  .ant-row {
    margin-top: 32px;
    .ant-col {
      background-color: $i-day-primary-white;
      border-radius: 8px;
      padding: 15px 0;
      .left-details {
        padding: 0 15px;
        .row {
          display: flex;
          border-bottom: 1px solid $i-day-grey-5;
          padding-bottom: 15px;
          margin-bottom: 15px;
          .banner-wrapper {
            width: 100%;
            @media (min-width: 900px) {
              width: 50%;
            }
            h4 {
              color: $i-day-grey-2;
              text-transform: uppercase;
              margin-bottom: 0;
            }
            p {
              font-weight: 500;
              margin-bottom: 0;
            }
            &:nth-child(2) {
              border-left: 1px solid $i-day-grey-5;
              padding-left: 15px;
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }

      .right-details {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        .row {
          border-bottom: 1px solid $i-day-grey-5;
          padding-bottom: 15px;
          margin-bottom: 15px;
          .banner-wrapper {
            @include mixin.flex(center, space-between);

            h4 {
              margin-bottom: 0;
            }
            p {
              margin-bottom: 0;
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
        .bottom-row {
          .banner-wrapper {
            @include mixin.flex(center, space-between);
          }
        }
        .ant-btn {
          width: 100%;
          height: 50px;
          background-color: $i-day-primary-green;
          @include mixin.flex(center, center);
          text-transform: uppercase;
          color: $i-day-primary-white;
          font-size: 15px;
          border-radius: 6px;
          margin-top: 20px;
        }
        svg {
          align-self: flex-end;
        }
      }
    }
  }
  .bottom-section {
    width: 100%;
    @include mixin.flex(center, space-between);
    padding: 16px 24px;
    border-radius: 8px;
    background-color: $i-day-primary-white;
    margin-top: 20px;
    .custom-btn {
      width: 100px;
      background-color: $i-day-primary-green;
      .btn-content {
        font-size: 16px;
      }
    }

    .btn-disabled {
      background-color: $i-day-grey-4;
    }
  }
}
