@import "../../styles/theme.scss";

.custom-date-range-picker {
  width: 280px;
  height: 44px;
  border-radius: 6px;
  border-color: $i-day-grey-5;
  .ant-picker-active-bar {
    display: none;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    margin-left: 1px;
    margin-right: 10px;
    order: -1;
  }
}

.date-range-separator {
  margin-left: -$space;
  width: $space;
}
