@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.wallet-layout-wrapper {
  background-color: $i-day-grey-6;
  width: 100%;
  min-height: 100vh !important;
  height: 100%;
  box-sizing: border-box;
  @include mixin.flex(center, center, column);
  padding: 32px;

  @media (max-width: 1280px) {
    padding: 16px;
  }

  .site-page-header {
    width: 100%;
    background-color: $i-day-primary-white;
    border-radius: 8px;
    padding: 30px 0 20px;
    .ant-page-header-heading {
      padding: 0 30px;
      @media (max-width: 800px) {
        column-gap: 16px;
      }
      .ant-page-header-heading-title {
        @include mixin.flex(center, flex-start);
        margin-right: 0;
        h3 {
          margin: 0;
        }
        .company-selector {
          margin-left: 10px;
          border-bottom: none;
          .dropdown-menu {
            box-shadow: none;
          }
        }
      }
    }
    .ant-page-header-content {
      padding: 0 10px;
    }
  }
  .ant-menu-horizontal {
    border-bottom: 0;
    .ant-menu-item {
      &:hover {
        color: $i-day-grey-0 !important;
      }
    }
    .ant-menu-item-selected {
      color: $i-day-grey-0 !important;
      font-weight: 700;
      &:after {
        display: none;
      }
    }
    .ant-menu-item-active {
      &:after {
        display: none;
        // border-bottom: 2px solid $i-day-primary-green !important;
      }
    }
  }
  .content-wrapper {
    width: 100%;
  }
  .ant-page-header-heading-left,
  .ant-page-header-heading-title {
    flex: 1;
  }
  & .company-selector {
    & .top-icon {
      h3 {
        margin-left: 16px !important;
      }
    }
  }
  & .dropdown-menu {
    top: 65px;
  }

  .ant-page-header-heading-extra {
    display: flex;
    align-items: center;
  }

  @media (max-width: 800px) {
    .ant-page-header-heading-left {
      flex: auto;
    }
    & .company-selector {
      & .menu-trigger {
        padding-right: 0;
      }
    }
  }
}

.wallet-layout-wrapper.ant-layout-has-sider {
  flex-direction: column !important;
  & .content-wrapper {
    width: 90% !important;
  }
}

.wallet-sider-menu {
  .ant-drawer-header {
    padding: 0 0 15px;
    margin: 16px 32px;
    .ant-drawer-close {
      width: 45px;
      height: 45px;
      background-color: $i-day-grey-6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mixin.flex(center, center);
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
  .ant-drawer-title {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: $i-day-grey-0;
  }
  .ant-drawer-body {
    padding: 0 32px 24px;
    display: flex;
    flex-direction: column;
    .profile-indicator {
      margin-top: auto;
      padding: 5px 10px;
      background-color: $i-day-grey-6;
      border-radius: 16px;
      @include mixin.flex(center, center);
    }
  }
  .ant-menu {
    border-right: none;
    svg {
      fill: transparent;
      stroke: $i-day-grey-0;
    }
    .ant-menu-item {
      @include mixin.flex(center, flex-start);
      padding: 0 !important;
      height: auto;
      margin-top: 20px;
      .ant-menu-item-icon {
        width: 45px;
        height: 45px;
        padding: 10px;
        background-color: $i-day-grey-6;
        border-radius: 50%;
        @include mixin.flex(center, center);
      }
    }
    .ant-menu-item-selected {
      background-color: transparent !important;
      color: $i-day-grey-0 !important;
      font-weight: 700;
      &:after {
        display: none;
      }
      .ant-menu-item-icon {
        background-color: $i-day-grey-0;
      }
      svg {
        stroke: $i-day-primary-white;
      }
    }
    a {
      font-weight: 600;
      color: $i-day-grey-0;
    }
  }
}

.wallet-mobile-company-selector-open {
  & .ant-drawer-content-wrapper {
    transform: none !important;
  }
}

@media (max-width: 576px) {
  .wallet-layout-wrapper {
    min-height: 100%;
    .ant-col {
      height: auto;
    }
    .active-menu-indicator {
      margin-bottom: 16px;
      width: 100%;
      @include mixin.flex(center, flex-start);
      h1 {
        margin: 0 0 0 15px;
        font-weight: 600;
      }
      .stripe-status-mobile {
        margin-left: auto;
      }
    }
    .empty-wrapper {
      height: auto;
    }
  }
  .wallet-sider-menu {
    .company-selector {
      padding-bottom: 16px;
    }
  }
}

.wallet-layout-header {
  padding: 46px $space-5;
  width: 100%;
  background-color: $i-day-primary-white;
  border-radius: $space;
}

.wallet-content-wrapper {
  padding: 32px 0 !important;
  padding-top: 0 !important;

  @media (max-width: 1280px) {
    padding: 16px 0 !important;
  }

  & > .ant-layout-content {
    background-color: transparent !important;
  }
}
