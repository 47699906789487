@import "../../../../../styles/theme.scss";

.container {
  display: flex;

  & svg {
    min-width: 32px;
  }
}

.contentWrapper {
  font-family: "Inter";
  font-style: normal;
  font-size: 13px;
  line-height: 19px;
  margin-top: 6.5px;
  margin-left: 12px;
  @media (max-width: 576px) {
    font-size: 15px;
    line-height: 21px;
  }
}

.notificationHeader {
  font-weight: 600;
  color: $i-day-grey-0;
  margin-bottom: 6.5px;
}

.notificationContent {
  font-weight: 400;
  color: $i-day-grey-0;
  margin-bottom: 8px;
}

.notificationDate {
  font-weight: 400;
  color: $i-day-grey-2;
  margin-bottom: 0;
}
