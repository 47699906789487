@import "../../../styles/theme.scss";

.secondary {
  margin-top: $space;
  display: flex;
  align-items: center;
  column-gap: $space;
  color: $i-day-grey-2;
}

.driverpayIndicator {
  width: 14px;
  height: 14px;
  border-radius: 50%;

  &.disabled {
    background-color: $i-day-grey-3;
  }

  &.enabled {
    background-color: $i-day-primary-green-lightest;
  }
}
