@import "../../styles/theme.scss";

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none !important;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-panel-container {
  .ant-picker-today-btn {
    color: $i-day-primary-green;
  }
  .ant-picker-header-view button:hover, .ant-picker-today-btn:hover {
    color: $i-day-primary-green-darker;
  }
  .ant-picker-header-view button:active, .ant-picker-today-btn:active,
  .ant-picker-header-view button:focus, .ant-picker-today-btn:focus {
    color: $i-day-primary-green-darkest;
  }
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}
.ant-picker.ant-picker-range {
  height: 44px;
  border-radius: 6px;
}

.ant-picker-focused,
.ant-picker:hover {
  border-color: $i-day-grey-0 !important;
}

.ant-picker-focused {
  box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%) !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: $i-day-grey-0 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: $i-day-primary-green !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background: $i-day-primary-green !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $i-day-primary-green !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: $i-day-primary-green-lightest !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):before {
  background: $i-day-primary-green-lightest !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-in-range
  ):after {
  border-color: $i-day-primary-green !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: $i-day-primary-green !important;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: $i-day-primary-white !important;
  background-color: $i-day-primary-green !important;
  corder-color: $i-day-primary-green-darkest !important;
}
