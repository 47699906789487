@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.driver-bookings-filters {
  width: 100%;
  margin-top: -10px;
  padding: 25px 30px 15px;
  background-color: $i-day-grey-7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  gap: 10px;
  .ant-picker {
    min-width: 350px;
    border-radius: 6px;
  }
  .custom-btn {
    width: 44px;
    @include mixin.flex(center, center);
    .btn-icon {
      transform: scale(2);
      stroke: rgb(51, 51, 51);
      &.disabled {
        stroke: $i-day-grey-3;
      }
    }
  }
  @media (max-width: 1180px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    & .ant-picker {
      width: 100%;
      min-width: auto;
    }
    .double-dropdown {
      min-width: auto;
    }
  }

  .driver-filter-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-column: 1 / -1;
    gap: 12px;
  }
}

.driver-bookings-wrapper {
  margin-top: 32px;
  margin-top: 16px;
  row-gap: 12px !important;
  .ant-col:first-child {
    overflow: auto;
  }
  .bookings-list-wrapper {
    height: auto;
    .booking-group {
      margin-bottom: 30px;
      .header {
        @include mixin.flex(center, space-between);
        padding-right: 32px;
        & h1 {
          color: $i-day-grey-2;
          margin-bottom: 8px;
        }
      }
      .booking-item-wrapper {
        background-color: $i-day-primary-white;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 24px 32px;
        .booking-status-section {
          @media (max-width: 400px) {
            padding-left: 0 !important;
          }
          & h2 {
            text-align: right;
            font-family: "Inter", sans-serif !important;
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 13px !important;
            line-height: 19px !important;
            text-align: right;
            color: $i-day-grey-0;
          }
          margin-left: auto;
        }
        .ant-row {
          border-bottom: 1px solid $i-day-grey-5;
          margin-bottom: 10px;
          padding-top: 10px;
          padding-bottom: 20px;
          cursor: pointer;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            border: none;
            margin-bottom: 0;
            padding-bottom: 0;
          }
          .ant-col {
            h2,
            h3 {
              font-size: 14px;
              margin-bottom: 0;
              font-weight: 400;
              line-height: 1.2;
            }
            h3 {
              color: $i-day-grey-2;
              margin-top: 10px;
            }
            .booking-status {
              @include mixin.flex(center, center);
              font-size: 12px;
              padding: 4px 8px;
              margin-top: 4px;
              border-radius: 6px;
              width: fit-content;
              margin-left: auto;

              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 11px;
              line-height: 13px;
              letter-spacing: 0.08em;
              text-transform: uppercase;

              @media (max-width: 520px) {
                min-width: 83px;
              }
            }
          }
        }
      }
    }
  }

  .activity-wrapper {
    border-radius: 8px;
    background-color: $i-day-primary-white;
    height: 100%;
    padding: 32px;
    padding-top: 16px;
    h1 {
      margin-bottom: 15px;
    }
    .filters {
      @include mixin.flex(center, flex-start);
      margin-bottom: 25px;
      .ant-select-selector {
        width: 130px;
        height: 44px;
        @include mixin.flex(center, center);

        margin-left: 15px;
        border-color: $i-day-grey-5;
        border-radius: 6px;
      }
    }
    .payment-type {
      margin: 15px 0;
      h1 {
        margin-bottom: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid $i-day-grey-5;
      }
      .breakdown-content {
        border-bottom: 1px solid $i-day-grey-5;
        padding: 10px 0;
        &:last-child {
          border-bottom: none;
        }
        span {
          @include mixin.flex(center, space-between);
          h4 {
            color: $i-day-grey-1;
            font-weight: 400;
          }
        }
      }
    }
  }

  .activity-header-mobile {
    padding: 10px 1rem;
    background-color: $i-day-grey-7;
    border: 1px solid #d9d9d9;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 32px);

    & > h1 {
      margin: 0;
    }
  }
}

.driver-bookings {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  .driver-bookings-wrapper {
    & .bookings-list-wrapper {
      height: 100%;
      min-height: calc(100vh - 345px);
    }
  }
}

@media (max-width: 768px) {
  .driver-bookings {
    & .driver-bookings-wrapper {
      flex-grow: 1;
    }
  }

  .driver-bookings-filters {
    padding: 0;
    flex-wrap: wrap;
    background: transparent;
    margin-top: 24px;
    grid-template-columns: 1fr;
    .custom-btn {
      width: 44px;
      order: 2;
      .btn-icon {
        transform: scale(2);
        stroke: rgb(51, 51, 51) !important;
        &.disabled {
          stroke: $i-day-grey-3 !important;
        }
      }
    }
  }
}

.booking-activity-mobile-drawer {
  & .ant-drawer-content-wrapper {
    height: 80% !important;
  }

  & .ant-drawer-body {
    padding: 0 !important;
  }

  & .driver-bookings-wrapper {
    margin-top: 0;
  }
}
