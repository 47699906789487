@use "src/styles/colors" as color;
@use "src/styles/variables/colors" as variablesColors;
@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.ant-tooltip-inner {
  height: 44px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  display: flex;
  align-items: center;
  color: $i-day-grey-0 !important;
  background: $i-day-primary-white !important;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;

  .hint-error & {
    color: variablesColors.$i-day-primary-red !important;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3) !important;
  }
}

.ant-tooltip-arrow-content::before {
  background: $i-day-primary-white !important;
}

.fleet-layout-wrapper {
  min-height: 100vh !important;
  z-index: 0;
  .ant-menu-item {
    @include mixin.flex(center, flex-start);
    border-left: 3px solid $i-day-primary-white;
    &:hover {
      color: color.$primary-color !important;
      background-color: $i-day-grey-7;
    }
    svg {
      color: $i-day-grey-2;
      min-width: 24px;
    }
  }
  .ant-menu-inline-collapsed {
    width: 63px !important;

    & .ant-menu-item {
      padding-left: 16px !important;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    color: $i-day-grey-0;
    font-weight: 600;
    border-left: 3px solid color.$primary-color;
    svg {
      color: $i-day-grey-0;
    }
  }
  .ant-layout-sider-children {
    @include mixin.flex(center, space-between, column);
    height: max-content;
    min-height: calc(100vh - 60px);
    transition: all 0.2s;
  }

  .ant-layout-sider-collapsed {
    & > .ant-layout-sider-children {
      @media (min-width: 576px) {
        max-width: 63px !important;
        position: fixed;
      }
    }
  }

  .ant-layout-sider-has-trigger {
    & > .ant-layout-sider-children {
      width: 100%;
      & .sider-children {
        width: 100%;
      }
      @media (min-width: 576px) {
        max-width: 200px;
        position: fixed;
      }
    }
  }
  .sidebar-top-content {
    width: 100%;
    flex: 1;
  }
  .sidebar-bottom {
    @include mixin.flex(center, flex-start, column);
    width: 100%;
    padding: 0 16px;
    .user-expand,
    .user-compact {
      width: 100%;
      background-color: $i-day-grey-6;
      @include mixin.flex(center, flex-start);
      border-radius: 360px;
      .name-in-circle {
        min-width: 32px;
        width: 32px;
        height: 32px;
        border-radius: 360px;
        background-color: $i-day-primary-green;
        font-size: 14px;
        color: $i-day-primary-white;
        @include mixin.flex(center, center);
      }
      h5 {
        font-size: 14px;
        margin: 0 0 0 20px;
        text-overflow: ellipsis;
        white-space: pre;
        overflow: hidden;
        margin-right: 12px;
      }
    }
    .user-compact {
      width: fit-content;
    }
  }

  .content-wrapper {
    @include mixin.flex(center, center, column);
    padding: 32px;
    box-sizing: border-box;
    width: 100%;
    background-color: $i-day-grey-6;
    .ant-layout-content {
      width: 100% !important;
      border-radius: 8px;
      background-color: $i-day-primary-white;
      overflow: hidden;
    }

    @media (max-width: 1280px) {
      padding: 16px;
    }
  }
}

.mobile-aside {
  box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%) !important;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  padding-bottom: 16px;
  overflow: auto;

  .ant-layout-sider-children {
    height: max-content;
    min-height: 100%;
  }

  .ant-layout-sider-zero-width-trigger {
    display: none;
  }

  @media (max-width: 576px) {
    z-index: 9999;
  }
}

.sider-children {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 100%;

  & .sidebar-top-content {
    display: grid;
    grid-template-rows: 44px 1fr;
    & > .ant-menu {
      margin-top: auto;
      margin-bottom: auto;
      @media (min-width: 576px) {
        max-width: 200px;
      }
    }
    @media (max-width: 576px) {
      grid-template-rows: 88px 1fr;
    }
  }

  @media (max-width: 576px) {
    height: calc(100vh - 16px);
  }
}

.sider-collapsed {
  width: 63px;

  & .menu-trigger {
    & h3 {
      display: none;
    }
    & > svg {
      display: none;
    }
    .top-icon {
      margin: auto;
    }
  }
}
