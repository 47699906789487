@use "../../styles/colors.scss" as color;
@use "../../styles/mixins.scss" as mixin;
@import "../../styles/theme.scss";

.mobile-filter-dropdown {
  & .options {
    position: static !important;
    border: none !important;
    filter: none !important;
    width: 100% !important;
  }

  & .ant-drawer-content-wrapper {
    height: auto !important;
  }

  & .ant-drawer-content {
    border-radius: 24px 24px 0 0;
  }

  &__button-wrapper {
    padding: 10px 20px 30px;
    align-self: stretch;
  }

  & .ant-drawer-body {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 32px;

    .ant-btn {
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        margin: auto;
      }
    }

    .mobileLabel {
      padding-left: 24px;
    }

    & p {
      color: $i-day-grey-0;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-size: 15px;
      margin-bottom: 24px;
      width: 100%;
      font-weight: 500;
      line-height: 28px;
    }

    & .ant-checkbox-wrapper {
      border-top: none !important;
      padding: 12px 24px !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      & > span:last-child {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        color: $i-day-grey-0;
        padding-left: 1rem;
      }

      .ant-checkbox {
        top: 0;
      }

      & .ant-checkbox-inner {
        width: 24px !important;
        height: 24px !important;

        &::after {
          left: 30% !important;
        }
      }
    }
  }
}

.filter-dropdown-wrapper,
.mobile-filter-dropdown {
  width: 100%;

  @media (max-width: 400px) {
    .filter-dropdown-wrapper {
      margin-left: auto;
    }
  }

  @media (max-width: 320px) {
    .filter-dropdown-wrapper:first-child {
      max-width: 10ch;
    }
  }

  position: relative;
  .ant-btn {
    @include mixin.flex(center, space-between);
    border-radius: 4px;
    background: #fcfcfc;
    padding-right: 10px;
    height: 44px !important;
    min-width: 130px;
    border-color: $i-day-grey-5;
    &:hover,
    &:focus {
      color: $i-day-grey-0;
      border-color: $i-day-grey-0;
    }
    &:active {
      color: $i-day-primary-green;
      border-color: $i-day-primary-green;
    }
  }

  .ant-checkbox-wrapper {
    &:hover {
      background-color: $i-day-grey-5;
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    &:hover .ant-checkbox-inner {
      border-color: color.$primary-color;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: color.$primary-color;
        border-color: color.$primary-color;
      }
    }
    .ant-checkbox-checked:after {
      border-color: $i-day-grey-0;
    }
  }

  .options {
    display: block;
    position: absolute;
    width: fit-content;
    top: calc(100% + 0.1rem);
    left: 0;
    z-index: 2;
    background-color: $i-day-primary-white;
    border: 1px solid $i-day-grey-5;
    border-radius: 0px 0px 6px 6px;
    filter: drop-shadow(3px 0px 3px rgba(0, 0, 0, 0.04));

    .ant-checkbox-wrapper {
      margin: 0;
      width: 100%;
      padding: 8px 20px;
      border-top: 1px solid $i-day-grey-5;
      cursor: pointer;
    }

    .ant-radio-wrapper {
      cursor: pointer;
      margin: 0;
      width: 100%;
      padding: 10px 20px;
      display: flex;
      align-items: center;

      .ant-radio {
        top: 0;
      }

      & > span {
        margin-right: 8px;
      }
    }

    & .ant-radio-checked .ant-radio-inner,
    & .ant-radio:hover .ant-radio-inner {
      border-color: color.$primary-color;
    }
    & .ant-radio-inner:after {
      background-color: color.$primary-color;
    }
    & .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: 0 0 0 3px $i-day-primary-green-lightest;
      border-color: color.$primary-color;
    }

    .ant-radio-inner {
      width: 24px;
      height: 24px;
    }
  }
}
