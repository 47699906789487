@import "../../styles/theme.scss";

.stripe-confirm-modal {
  & * {
    font-family: Inter, sans-serif;
  }

  .ant-drawer-content-wrapper {
    height: 100vh !important;
  }

  .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-body {
    padding: 0 !important;

    & > div {
      max-width: 100%;
    }
  }

  .select-another-company-wrapper {
    width: 100%;
    padding: 0 24px;
  }

  .select-another-profile {
    margin-bottom: 0;
    margin-top: 12px;
    text-align: center;
  }

  .select-another-profile-selector {
    &:hover .ant-select-selector {
      border-color: $i-day-grey-0 !important;

      & .ant-select-selection-item,
      & + .ant-select-arrow {
        color: $i-day-grey-0;
      }
    }
    &.ant-select-open .ant-select-selector {
      border-color: $i-day-grey-0;
      box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%);
      & .ant-select-selection-item,
      & + .ant-select-arrow {
        color: $i-day-grey-0;
      }
    }
  }

  .ant-select {
    width: 100%;
    margin-top: 8px;

    & .ant-select-selector {
      height: 44px !important;
      border-radius: 6px !important;
    }

    & .ant-select-selection-item {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      display: flex;
      align-items: center;
      @media (max-width: 400px) {
        text-align: center;
        white-space: break-spaces;
        line-height: 18px !important;
      }
    }

    & .ant-select-selection-search {
      display: none !important;
    }
  }
}

div:has(.confirmation-modal-select) {
  z-index: 9999;
}

.confirmation-modal-select
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(136, 136, 136, 33%) !important;
}

.stripeModalMobile {
  position: fixed;
  left: 0;
  top: 0;
  height: 100svh;
  width: 100vw;
  overflow: auto;
  background-color: $i-day-primary-white;
}

.overflow-hidden {
  position: fixed;
  height: 0;
  overflow: hidden;
}
