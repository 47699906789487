@import "../../styles/theme.scss";

$horizontal-padding: $space-2;
$vertical-padding: $space-2-alternative;

.datePicker {
  border: 1px solid $i-day-grey-4 !important;
  padding: $vertical-padding $horizontal-padding !important;
  width: 100% !important;

  font-size: 15px !important;
  line-height: 21px !important;

  color: $i-day-grey-0 !important;
  background: $i-day-grey-8 !important;
  border-radius: 6px !important;

  & :global(.ant-picker-suffix) {
    display: none;
  }
}

.invalid {
  border-color: $i-day-primary-red-darkest !important; /* it competes with antd styles */
  outline-color: $i-day-primary-red-darkest;
}
