@import "../../styles/theme.scss";

.custom-searchbar {
  width: auto;
  height: 44px;
  border-radius: 6px !important;
  border: 1px solid $i-day-grey-0;

  &.ant-input-affix-wrapper:hover {
    border-color: $i-day-grey-0 !important;
  }
  &.ant-input-affix-wrapper-focused {
    border-color: $i-day-grey-0 !important;
    box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%) !important;
  }
}
