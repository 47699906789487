@import "../../styles/theme.scss";

.wrapper {
  display: flex;
  align-items: center;
  gap: $space-2-alternative;
  margin: $space-4 0;
}

.content {
  display: flex;
  flex-direction: column;
}

.text {
  margin: 0;
  font-size: 15px;
  letter-spacing: -0.005em;
  text-align: left;
  @media (max-width: 576px) {
    font-size: 17px;
    letter-spacing: -0.085px;
  }
}

.heading {
  font-weight: 600;
  color: $i-day-grey-0;
}

.subheading {
  font-weight: 400;
  color: $i-day-grey-1;

  @media (max-width: 576px) {
    font-size: 15px;
    letter-spacing: -0.085px;
  }
}
