@use "../../styles/mixins.scss" as mixin;
@import "../../styles/theme.scss";

.custom-btn {
  height: 44px !important;
  border-radius: 6px !important;
  width: 100%;
  color: $i-day-primary-white !important;

  &.ant-btn.ant-btn-default:hover,
  &.ant-btn.ant-btn-default:focus {
    color: $i-day-grey-0;
    border-color: $i-day-grey-0;
  }
  &.ant-btn.ant-btn-default:active {
    border-color: $i-day-grey-0 !important;
    box-shadow: 0 0 0 2px rgba(136, 136, 136, 33%) !important;
  }

  .btn-content {
    font-size: 18px;
    @include mixin.flex(center, center);
  }
}
