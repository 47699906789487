@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.fleets-list-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 780px) {
    flex-direction: column;

    & .ant-tabs-nav-wrap {
      justify-content: center;
      margin: 0 !important;
    }
  }

  & .ant-tabs-content-holder {
    overflow: auto;
  }

  & .ant-tabs-content {
    height: 100%;

    .settings-tab {
      background-color: $i-day-grey-8;
    }
  }

  .ant-tabs {
    height: 100%;
  }

  .header {
    padding: 32px 32px 0 32px;
    @include mixin.flex(center, space-between);

    @media (max-width: 780px) {
      flex-direction: column;
    }

    @media (max-width: 780px) {
      & .action-section {
        width: 100%;
      }
    }

    .title-section {
      @include mixin.flex(center, flex-start);
      div {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 1px solid $i-day-grey-5;
        @include mixin.flex(center, center);
      }
      h1 {
        margin: 0 0 0 10px;
        font-size: 26px;
      }

      @media (max-width: 780px) {
        align-self: flex-start;
        margin-bottom: 10px;
      }
    }
    .action-section {
      display: flex;
      .ant-input-affix-wrapper {
        height: 44px;
        border-radius: 6px;
        border-color: $i-day-grey-5;
        margin-right: 20px;
      }
      .ant-btn {
        @include mixin.flex(center, flex-start);
        height: 44px;
        border-radius: 6px;
        border-color: $i-day-grey-5;
        background-color: $i-day-primary-green;
        span {
          margin: 0 3px;
          color: $i-day-primary-white;
        }
      }
      @media (max-width: 400px) {
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .ant-tabs {
    margin-top: 10px;
    overflow: auto;

    .ant-tabs-nav {
      margin: 0;
      &:before {
        content: none;
      }
      .ant-tabs-tab:nth-child(2) {
        margin-left: 50px;
      }
      .ant-tabs-nav-wrap {
        margin: 0 32px 0 88px;
        border-bottom: 1px solid $i-day-grey-5;
      }
      .ant-tabs-ink-bar {
        display: none;
      }
      .ant-tabs-tab-btn,
      .ant-badge {
        color: $i-day-grey-2;
        .ant-badge-count {
          top: 5px;
          right: -13px;
          background-color: $i-day-grey-2;
          border-radius: 6px;
        }
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn,
        .ant-badge {
          color: $i-day-grey-0 !important;
          // font-weight: 600;
          .ant-badge-count {
            background-color: $i-day-grey-0;
          }
        }
      }
    }

    .companies-table-wrapper {
      overflow: auto hidden;
      margin-top: 10px;

      @media (min-width: 576px) {
        & .companies-table-container {
          min-width: 1000px;
        }
        min-height: calc(100% - 10px);
      }

      .companies-table-container {
        @media (max-width: 576px) {
          padding-bottom: 16px;
        }
      }
      .header {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 24px;
        padding: 10px 40px;
        div {
          color: $i-day-grey-2;
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          letter-spacing: 0.08em;
          text-align: left;
          &:first-child {
            width: 150px;
          }
        }
      }

      .row {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 24px;
        border-bottom: 1px solid $i-day-grey-5;
        padding: 0 40px;

        &:last-child {
          border-bottom: none !important;
        }
        &:nth-child(even) {
          background-color: $i-day-grey-8;
        }
      }
      .cell {
        padding: 20px 0;
        color: $i-day-grey-0;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: -0.01em;
      }
    }
    .fleet-admin-settings {
      padding-top: 80px;
      background-color: $i-day-grey-8;
      width: 100%;
      @include mixin.flex(center, flex-start, column);

      @media (max-width: 620px) {
        & .ant-form-item-row {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .ant-form-item-control {
            flex: 1 1;
          }
        }
      }

      .ant-form-item {
        width: 40%;
        &:last-child {
          .ant-row {
            border-bottom: 0;
          }
        }

        @media (max-width: 850px) {
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      .ant-row {
        padding-bottom: 20px;
        border-bottom: 1px solid $i-day-grey-5;
        .ant-form-item-label {
          color: $i-day-grey-0;
          font-weight: 600;
        }
        .ant-switch-checked {
          background: $i-day-primary-green;
        }
        .ant-form-item-control-input-content {
          text-align: end;
        }
      }
      .ant-btn {
        @include mixin.flex(center, flex-start);
        height: 44px;
        border-radius: 6px;
        border-color: $i-day-primary-green;
        background-color: $i-day-primary-white;
        span {
          margin: 0 3px;
          color: $i-day-primary-green;
          text-transform: uppercase;
        }
      }
    }
  }
}
