@use "../../styles/colors.scss" as color;
@import "../../styles/theme.scss";

// TODO: Create radio component without ant
.custom-radio-group {
  display: flex;

  & .ant-radio-checked .ant-radio-inner,
  & .ant-radio:hover .ant-radio-inner {
    border-color: color.$primary-color;
  }
  & .ant-radio-inner:after {
    background-color: color.$primary-color;
  }
  & .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px $i-day-primary-green-lightest;
    border-color: color.$primary-color;
  }
}
