@use "src/styles/mixins" as mixin;

.loading-logo {
  @include mixin.flex(center, center);
  min-height: 100%;
  svg {
    width: 100px;
    height: 100px;
  }
}

.full-loader {
  min-height: 100vh;
}

.long-data-loading {
  min-height: 350px;
}

.small-loading-logo {
  svg {
    width: 44px;
    height: 44px;
  }
}
