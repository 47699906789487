@import "../../../styles/theme.scss";

.goBack {
  margin: 60px auto 0;

  @media (max-width: 576px) {
    max-width: 240px !important;
  }
}

.wrapper {
  padding: 46px 40px;
}

.form {
  margin-top: 32px;
  padding: 32px;
  border-radius: 8px;
  background: $i-day-primary-white;
}
.form {
  &:first-child,
  &:last-child {
    margin-top: 0;
  }
}
