@use "src/styles/mixins" as mixin;
@import "../../styles/theme.scss";

.home-wrapper {
  & * {
    font-family: Inter, sans-serif;
  }

  height: 100%;
  @media (min-width: 1280px) {
    margin-top: 24px;
  }
  .earnings-wrapper {
    border-radius: 8px;
    background-color: $i-day-primary-white;
    height: 100%;
    padding: 32px;

    .earningsHeaderWrapper {
      display: grid;
      grid-template-columns: 8ch 1fr;
      align-items: center;

      @media (max-width: 576px) {
        h1 {
          margin-bottom: 0;
          margin-right: 16px;
        }

        .ant-btn {
          padding: 4px 8px;
          height: 32px !important;
          background: $i-day-primary-green !important;
          border-radius: 9999px !important;
          min-width: auto !important;

          & span {
            font-style: normal;
            margin: auto;
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            color: $i-day-primary-white;
          }

          & svg {
            display: none;
          }
        }
        & .ant-btn-default {
          background: $i-day-primary-green !important;
          border-color: $i-day-primary-green !important;
        }
        & .ant-btn-default:hover {
          background: $i-day-primary-green-darker !important;
          border-color: $i-day-primary-green-darker !important;
        }
        & .ant-btn-default:active,
        & .ant-btn-default:focus {
          background: $i-day-primary-green-darkest !important;
          border-color: $i-day-primary-green-darkest !important;
        }
      }
    }

    @media (max-width: 800px) {
      padding: 24px !important;
    }

    .recharts-text {
      fill: $i-day-grey-2;
      font-size: 11px;
    }

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 19px;
      color: $i-day-grey-0;
      margin-bottom: 16px;
    }
    h2 {
      font-size: 28px;
      color: $i-day-grey-0;
      margin: 16px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
    }
    .filters {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      column-gap: 12px;
      .ant-btn {
        width: 100%;
      }

      @media (max-width: 576px) {
        column-gap: 8px;
      }

      @media (max-width: 400px) {
        column-gap: 0;
      }
    }
    .breakdown {
      margin-top: 16px;
      h1 {
        margin-bottom: 0;
        padding-bottom: 8px;
        border-bottom: 1px solid $i-day-grey-5;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        color: $i-day-grey-0;

        @media (max-width: 576px) {
          font-size: 15px;
        }
      }
      .breakdown-content {
        border-bottom: 1px solid $i-day-grey-5;
        padding: 16px 0;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        span {
          @include mixin.flex(center, space-between);
          h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 19px;
            color: $i-day-grey-0;
            margin-bottom: 4px;
            @media (max-width: 576px) {
              font-size: 15px;
            }
          }
        }
        div {
          @include mixin.flex(center, space-between);
          & h4 {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: $i-day-grey-1;
            margin-bottom: 0;
            @media (max-width: 576px) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  .balance-payout-wrapper {
    display: flex !important;
    flex-direction: column !important;

    @media (max-width: 800px) {
      row-gap: 16px !important;
    }
    .ant-col {
      border-radius: 8px;
      background-color: $i-day-primary-white;
      padding: 32px !important;

      @media (max-width: 800px) {
        padding: 24px !important;
      }

      .balance-amount {
        border-bottom: 1px solid $i-day-grey-5;
        padding: 16px 0;
        margin-bottom: 16px;
      }
      .ant-input-number-group-wrapper {
        width: 130px;
        margin-bottom: 12px;
      }
      h1 {
        padding-bottom: 5px;
        margin-bottom: 0;
        border-bottom: 1px solid $i-day-grey-5;
      }
      h2 {
        margin-bottom: 0;
        line-height: 1;
      }

      .fast-pay-amt {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        & svg {
          height: 18px;
          width: 18px;
        }
        h3 {
          font-style: normal;
          margin-left: 8px;
          font-weight: 600;
          font-size: 13px;
          line-height: 19px;
          color: $i-day-grey-0;
          margin-bottom: 0;

          @media (max-width: 576px) {
            font-size: 15px;
            line-height: 21px;
          }
        }
      }
    }

    .drawdown-text {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: $i-day-grey-1;
      margin-bottom: 16px;

      & svg {
        height: 18px;
        width: 18px;
        margin-right: 8px;
        margin-bottom: -4px;
      }

      @media (max-width: 800px) {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
  .ant-row {
    margin: 0 !important;
    .ant-col {
      height: 100%;
    }
  }

  .payout-header {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $i-day-grey-0;
    padding-bottom: 8px !important;

    @media (max-width: 800px) {
      font-size: 15px;
    }
  }

  .amount-text {
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    letter-spacing: -0.02em;
    color: $i-day-grey-0;

    @media (max-width: 800px) {
      font-size: 44px;
    }
  }

  .amount-text.lastPayout {
    font-size: 26px;
    margin-top: 16px;
  }

  .lastPayoutDate {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: $i-day-grey-1;
    margin-bottom: 0;
  }
}
