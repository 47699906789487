@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.reports-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    background: $i-day-grey-6;
  }

  .actions-header-section {
    @include mixin.flex(center, space-between);
    justify-content: flex-end;

    @media (min-width: 1050px) {
      margin-bottom: 40px;
    }

    @media (min-width: 576px) and (max-width: 1050px) {
      margin-bottom: 24px;
    }

    @media (max-width: 1500px) and (min-width: 1050px) {
      & > .custom-dropdown-wrapper {
        order: 3;
        margin-left: 8px;
      }
    }

    @media (max-width: 1280px) {
      padding: 32px 24px 0;
    }

    padding: 40px 32px 0;

    h1 {
      font-size: 26px;
      color: $i-day-grey-0;
      letter-spacing: -0.02em;
      margin: 0;
      margin-right: auto;
      white-space: pre;
    }

    @media (max-width: 1050px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      & > .filters {
        grid-column: 1 / -1;
        margin-top: 10px;
      }

      padding-top: 24px;

      & > .custom-dropdown-wrapper {
        margin-left: auto;
      }
    }

    @media (max-width: 576px) {
      grid-template-columns: 64px 1fr 1fr;
      padding: 0;
    }
  }
  .filters {
    @include mixin.flex(center, center);
    gap: 12px;
    margin-left: auto;

    @media (max-width: 576px) {
      padding-top: 90px !important;
      background-color: #f0f2f5;
      padding-left: 16px;
      padding-right: 16px;
    }

    .ant-input-affix-wrapper {
      height: 44px;
      border-radius: 6px;
      background: $i-day-grey-8;
      & input {
        background: $i-day-grey-8;
      }
      @media (min-width: 1050px) {
        max-width: 250px;
      }

      @media (max-width: 576px) {
        height: 56px;
      }
      border-color: $i-day-grey-5;
      border: 1px solid $i-day-grey-5;
      border-left: none;
      box-shadow: none;
      &:hover {
        border-color: $i-day-grey-5;
      }
    }

    .filter-dropdown-wrapper > button {
      height: 44px;
      border-radius: 6px;
    }

    .ant-btn {
      box-shadow: none;
    }

    .ant-input-group-wrapper {
      width: 250px;
      border-radius: 6px;
      height: 44px;
      @media (max-width: 576px) {
        height: 56px;
      }

      .ant-input-group-addon {
        border: 1px solid $i-day-grey-5;
        border-right: none;
        padding-right: 0;
        background-color: $i-day-grey-8;
        & span svg {
          width: 16px;
          height: 16px;
          margin-top: 4px;
          fill: $i-day-grey-3;

          @media (max-width: 576px) {
            width: 20px;
            height: 20px;
          }
        }
      }
      .ant-input-wrapper {
        height: 44px;
        @media (max-width: 576px) {
          height: 56px;
        }
        border-radius: 6px;
        border-color: $i-day-grey-5;
      }
      .ant-input,
      .ant-select-selector {
        height: 44px;
        @include mixin.flex(center, center);
        @media (max-width: 576px) {
          height: 56px;
        }
      }

      @media (max-width: 1050px) {
        width: 100%;
      }
    }
    .ant-picker {
      width: 270px;
      height: 44px;
      border-radius: 6px;
      border-color: $i-day-grey-5;
      .ant-picker-active-bar {
        display: none;
      }

      @media (max-width: 1050px) {
        width: 100%;
      }

      @media (max-width: 576px) {
        height: 56px;
      }
    }
    .ant-picker .ant-picker-input span.ant-picker-suffix,
    .ant-picker.ant-picker-range span.ant-picker-suffix {
      margin-left: 1px;
      margin-right: 10px;
      order: -1;
    }
    .ant-input-wrapper.ant-input-group {
      & .ant-input-group-addon {
        border-radius: 6px 0 0 6px;
      }

      & .ant-input-affix-wrapper {
        border-radius: 0 6px 6px 0;
      }

      .ant-input {
        height: 34px;
        @media (max-width: 576px) {
          height: 46px;
        }
      }
    }

    .filter-dropdown-wrapper > button {
      & > svg:first-child {
        & path {
          stroke: $i-day-grey-2;
        }
        margin-right: 8px;
      }
      @media (max-width: 576px) {
        height: 56px;
        border-radius: 6px;
      }
    }

    .ant-picker-range {
      & .ant-picker-suffix {
        & path {
          stroke: $i-day-grey-2;
        }
      }
    }

    @media (max-width: 1280px) and (min-width: 1050px) {
      .filter-dropdown-wrapper > button {
        width: 100%;
        & > svg:first-child {
          display: none;
        }
      }

      .ant-picker-range {
        & .ant-picker-suffix {
          display: none;
        }
      }
    }

    @media (max-width: 1050px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      max-width: 100%;
      width: 100%;

      .filter-dropdown-wrapper > button {
        width: 100%;
      }

      .payment-type {
        grid-column-end: auto;
      }

      .custom-dropdown-wrapper {
        grid-column-start: 2;

        @media (max-width: 630px) {
          grid-column-start: auto;
        }
      }
    }

    @media (max-width: 630px) {
      grid-template-columns: 1fr;
    }
  }

  .reports-footer {
    width: 100%;
    height: 48px;
    @include mixin.flex(center, space-between);
    padding: 0 32px;
    border-top: 1px solid $i-day-grey-6;
    span {
      @include mixin.flex(center, center);
      & svg {
        margin-right: 10px;
      }
      h3 {
        font-size: 14px;
        margin: 0;
        color: $i-day-grey-2;
      }
    }
    @media (max-width: 576px) {
      background-color: #ffdddf;
    }
  }

  .scrollTop {
    color: $i-day-grey-2;
    cursor: pointer;
    text-decoration: underline;
    font-family: Inter, Roboto, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .reports-sticky-header {
    display: grid;
    grid-template-columns: 64px 1fr 1fr;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 16px;
    z-index: 1;
    background-color: $i-day-primary-white;
    border-bottom: 1.5px solid rgba(51, 51, 51, 0.08);

    & > .custom-dropdown-wrapper {
      margin-left: auto;
    }
  }
}
