@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.payments-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $i-day-grey-8;
  & * {
    font-family: "Inter", sans-serif;
  }
  .header {
    padding: 32px;
    display: flex;
    background-color: $i-day-primary-white;

    @media (max-width: 576px) {
      display: flex;
      align-items: center;
      & > div {
        margin-right: 16px;
      }
      padding: 16px;
    }

    h1 {
      font-size: 26px;
      color: $i-day-grey-0;
      letter-spacing: -0.02em;
      margin: 0;
      margin-right: auto;
    }
  }

  .content {
    overflow-y: auto;
    background-color: $i-day-grey-8;
    border-top: 1px solid $i-day-grey-5;
    @include mixin.flex(center, flex-start, column);

    @media (max-width: 900px) {
      & > div,
      & > .auto-approval-description,
      & > .contact-links {
        width: 100% !important;
      }

      padding: 20px;
    }

    h1,
    h2 {
      color: $i-day-grey-0;
      font-family: "Inter";
      font-weight: 600;
    }

    h1 {
      width: calc(100% - 64px);
      margin: auto;
      margin-top: 40px;
      margin-bottom: 20px;
      padding-bottom: 24px;
      border-bottom: 1px solid $i-day-grey-5;
      font-style: normal;
      font-size: 15px;
      line-height: 24px;
      @media (max-width: 576px) {
        margin: 0;
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }

    h2 {
      font-style: normal;
      font-size: 20px;
      line-height: 26px;
      width: 100%;
      max-width: 480px;
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid $i-day-grey-5;
    }
    .ant-switch-checked {
      background: $i-day-primary-green;
    }
    .auto-approval-description {
      width: 45em;
      margin-bottom: 20px;
    }
    .banner-wrapper {
      max-width: 480px;
      width: 100%;
      margin-top: 25px;
      h2 {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $i-day-grey-5;
      }
      p {
        @include mixin.flex(center, space-between);
      }
    }
    .payment-frequency-wrapper {
      @include mixin.flex(center, flex-start);
      max-width: 480px;
      width: 100%;
      .input-box {
        display: flex;
        flex-direction: column;
        flex: 1;
        &:not(:only-child) {
          margin-right: 20px;
        }
        &:last-of-type:not(:only-child) {
          margin-right: 0;
        }
        label {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $i-day-grey-1;
          margin-bottom: 8px;

          @media (max-width: 576px) {
            font-size: 13px;
            line-height: 19px;
            letter-spacing: 0.08em;
            color: $i-day-grey-2;
          }
        }
        .ant-select {
          .ant-select-selector {
            height: 44px;
            border-radius: 6px;
            @include mixin.flex(center, flex-start);
          }
          &:nth-child(2) {
            margin: 0;
          }
          .ant-select-selection-item {
            font-size: 15px;
          }
        }
      }

      @media (max-width: 576px) {
        flex-direction: column;
        & .input-box {
          width: 100%;
          margin-right: 0 !important;
          &:last-child {
            margin-top: 20px;
          }
          &:first-child {
            margin-top: 10px;
          }
        }
      }
    }
    .payment-frequency-submit {
      max-width: 480px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 16px;
    }
    .ant-btn {
      @include mixin.flex(center, center);
      height: 40px;
      color: $i-day-primary-green;
      border-color: $i-day-primary-green;
      text-transform: uppercase;
      font-size: 16px;
      border-radius: 6px;
    }
    .contact-links {
      max-width: 480px;
      width: 100%;
      @include mixin.flex(center, flex-start);
      .banner-wrapper {
        margin-top: 10px;
        h3 {
          font-size: 12px;
          color: $i-day-grey-2;
          text-transform: uppercase;
        }
        p {
          font-size: 14px;
          color: $i-day-grey-0;
          font-weight: 600;
        }
      }
    }
  }
}
