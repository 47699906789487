@import "../../styles/theme.scss";

.input {
  background: $i-day-primary-white;
  border: 1px solid $i-divider-color !important;
  border-radius: 6px !important;
  height: 44px;
  padding-left: 16px !important;

  @media (max-width: 576px) {
    height: 56px;
  }
}

.inputDisabled {
  pointer-events: none;
}

.inputWrapper {
  position: relative;
}

.errorText {
  position: absolute;
  left: 8px;
  top: 100%;
}

.inputError {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: -0.01em;
  color: $i-day-primary-red;
  margin: 0;
}
