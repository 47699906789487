@use "src/styles/colors" as color;
@use "src/styles/mixins" as mixin;
@import "../../../styles/theme.scss";

.drivers-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .name {
    justify-content: flex-start !important;
    text-align: left !important;
  }

  .actions-header-section {
    @include mixin.flex(center, space-between);
    justify-content: flex-end;
    padding: 32px 32px 0;
    h1 {
      font-size: 26px;
      color: $i-day-grey-0;
      letter-spacing: -0.02em;
      margin: 0;
      margin-right: auto;
    }
    @media (max-width: 1280px) {
      padding: 32px 24px 0;
    }

    & > .custom-dropdown-wrapper {
      @media (max-width: 1200px) and (min-width: 850px) {
        order: 3;
        margin-left: 20px;
      }

      @media (max-width: 850px) {
        margin-left: auto;
      }
    }

    @media (max-width: 850px) {
      display: grid;
      grid-template-columns: 1fr;

      padding-top: 24px;

      & > .filters {
        margin-left: 0 !important;
        grid-column: 1 / -1;
        margin-top: 10px;
      }
    }

    @media (max-width: 576px) {
      grid-template-columns: 64px 1fr;
      padding-left: 0;
      padding-right: 0;
      background-color: #f0f2f5;
    }

    .drivers-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      @media (max-width: 850px) {
        justify-content: space-between;
      }
    }

    .filters {
      gap: 12px;
      grid-template-columns: 1fr 44px 150px;
      display: grid;
      margin-left: 1rem;

      .refresh-btn {
        width: 44px;
        height: 44px;
        border: 1px solid $i-day-grey-5;
        border-radius: 6px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        background-color: $i-day-primary-white;
        @media (max-width: 576px) {
          width: 56px;
          height: 56px;
        }
      }

      .new-driver-btn {
        height: 44px;
        width: 150px;
        cursor: pointer;
        background: $i-day-primary-white;
        border: 1.5px solid $i-day-primary-green;
        border-radius: 99999px;
        display: flex;
        align-items: center;
        justify-content: center;
        & p {
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 21px;
          color: $i-day-primary-green;
          margin: 0;
          margin-left: 8px;
        }
        & path {
          stroke: $i-day-primary-green;
        }

        @media (max-width: 850px) {
          grid-column: 1 / -1;
          width: 100%;
        }
      }

      .new-driver-btn-disabled {
        border-color: $i-day-grey-2;
        pointer-events: none;

        & p {
          color: $i-day-grey-2;
        }

        & path {
          stroke: $i-day-grey-2;
        }
      }

      @media (max-width: 576px) {
        margin-top: 72px !important;
        grid-template-columns: 1fr 56px !important;
        padding-left: 16px;
        padding-right: 16px;
      }

      & > .ant-picker,
      .ant-input-affix-wrapper {
        width: 100%;
        min-width: 280px;

        @media (max-width: 500px) {
          min-width: auto;
        }
      }

      @media (max-width: 850px) {
        grid-template-columns: 1fr 44px;
        width: 100%;
        align-items: center;
      }
      .ant-input-affix-wrapper {
        height: 44px;
        border-radius: 6px;
        border-color: $i-day-grey-5;
        background: $i-day-grey-8;

        & input {
          background: $i-day-grey-8;
        }

        @media (max-width: 1200px) {
          width: 100%;
        }

        @media (max-width: 576px) {
          height: 56px;
        }
      }
      .ant-btn {
        @include mixin.flex(center, center);
        height: 44px;
        border-radius: 6px;
        border-color: $i-day-grey-5;
        span {
          margin: 0 3px;
          color: $i-day-grey-0;
        }
      }
      .add-btn {
        background-color: $i-day-primary-green;
        span {
          color: $i-day-primary-white !important;
        }
      }
      .ant-picker {
        height: 44px;
        border-radius: 6px;
        border-color: $i-day-grey-5;
        .ant-picker-active-bar {
          display: none;
        }
        @media (max-width: 1200px) {
          width: 100%;
        }
      }
      .ant-picker .ant-picker-input span.ant-picker-suffix,
      .ant-picker.ant-picker-range span.ant-picker-suffix {
        margin-left: 1px;
        margin-right: 10px;
        order: -1;
      }
    }
  }

  .driver-list-header-section-wrapper {
    @media (max-width: 576px) {
      margin-bottom: 16px;
    }
  }
  .drivers-table-wrapper {
    flex-grow: 1;
    height: 100%;
    overflow: auto hidden;
    .driver-list-table-wrapper {
      overflow: auto hidden;
    }

    .driver-list-table-section-wrapper {
      @media (min-width: 576px) {
        min-width: 1020px;
        margin-top: 26px;
        min-height: calc(100vh - 286px);
      }

      @media (max-width: 576px) {
        & > .mobile-row {
          padding: 0;
          margin-bottom: 16px;
          .payout-component-wrapper {
            width: 100%;
          }
        }
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    .header {
      display: grid;
      grid-template-columns: 160px 1fr 1fr 200px 1fr 24px;
      padding: 10px 32px;
      border-bottom: 1px solid $i-day-grey-6;
      border-top: 1px solid $i-day-grey-6;
      div {
        color: $i-day-grey-2;
        font-weight: 400;
        font-size: 11px;
        line-height: 19px;
        letter-spacing: 0.08em;
        font-family: "Inter";
        font-style: normal;
        text-align: center;
        &:first-child {
          width: 150px;
          text-align: left;
        }
      }
    }

    .infinite-scroll-component__outerdiv {
      .infinite-scroll-component {
        .trip {
          color: #8cc0de !important;
          text-decoration: underline;
          span {
            cursor: pointer;
            color: $i-day-primary-green;
          }
        }
        .no-trip {
          color: $i-day-grey-3 !important;
          & span {
            color: $i-day-grey-3 !important;
            pointer-events: none;
          }
        }
        .row {
          display: grid;
          grid-template-columns: 160px 1fr 1fr 200px 1fr 24px;
          border-bottom: 1px solid $i-day-grey-5;
          background-color: $i-day-grey-8;
          padding: 0 32px;
          &:last-child {
            border-bottom: none !important;
          }
        }
        .row.selected {
          background-color: $i-day-primary-white;
          & .cell {
            font-weight: 600;
          }
        }
        .cell {
          @include mixin.flex(center, center);
          padding: 8px 0 !important;
          color: $i-day-grey-0;
          font-size: 13px;
          line-height: 21px;
          letter-spacing: -0.01em;
          &:first-child {
            justify-content: flex-start;
            .ant-checkbox-wrapper {
              margin-right: 10px;
              .ant-checkbox-input:focus + .ant-checkbox-inner,
              .ant-checkbox-wrapper:hover .ant-checkbox-inner,
              .ant-checkbox:hover .ant-checkbox-inner {
                border-color: color.$primary-color;
              }
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background-color: color.$primary-color;
                  border-color: color.$primary-color;
                }
              }
            }
          }
        }

        .capitalize {
          text-transform: capitalize;
        }
      }
    }

    @media (max-width: 576px) {
      background-color: #f0f2f5;
    }
  }

  .driver-invite-tick {
    display: flex;
    align-items: center;
    & path {
      stroke: $i-day-primary-green;
    }
  }

  .drivers-footer {
    width: 100%;
    height: 48px;
    @include mixin.flex(center, space-between);
    padding: 0 32px;
    // margin-top: 20px;
    border-top: 1px solid $i-day-grey-5;
    span {
      @include mixin.flex(center, center);
      & svg {
        margin-right: 10px;
      }
      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.005em;
        color: $i-day-grey-2;
        margin: 0;
      }
    }
  }
  .scrollTop {
    color: $i-day-grey-2;
    cursor: pointer;
    text-decoration: underline;
    font-family: Inter, Roboto, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .drivers-sticky-header {
    display: grid;
    grid-template-columns: 64px 1fr;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 16px;
    z-index: 3;
    background-color: $i-day-primary-white;

    & > .custom-dropdown-wrapper {
      margin-left: auto;
    }
  }
}

.status-tag {
  @include mixin.flex(center, center);
  width: 136px;
  height: 24px;
  padding: 0 7px;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  white-space: pre;
  text-transform: uppercase;
  background-color: $i-day-primary-white;

  @media (max-width: 576px) {
    margin-right: auto;
  }
}

.status-tag.red {
  color: $i-day-primary-red;
  border: 1px solid $i-day-primary-red;
}

.status-tag.green {
  color: $i-day-primary-green;
  border: 1px solid $i-day-primary-green;
}

.status-tag.blue {
  color: $i-day-primary-blue;
  border: 1px solid $i-day-primary-blue;
}

.status-tag.grey {
  color: $i-day-grey-2;
  border: 1px solid $i-day-grey-2;
}

.status-tag.yellow {
  color: $i-day-primary-yellow;
  border: 1px solid $i-day-primary-yellow;
}

.drivers-mobile-bulk-invite-modal {
  & * {
    font-family: Inter, sans-serif;
  }
  .ant-drawer-content {
    border-radius: 24px 24px 0 0;
  }
  .ant-drawer-body {
    padding: 32px 24px;
    padding-bottom: 8px;
    .drawer-title {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.01em;
      color: $i-day-grey-0;
      margin: 0;
      margin-bottom: 22px;
    }

    & .ant-btn {
      width: 100%;
      border-radius: 6px;
      height: 56px;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: 1px;
      background: $i-day-primary-green;
      border-radius: 32px;
      margin: 32px 0;
      margin-top: 22px !important;
      margin-bottom: 10px !important;
      & span {
        color: $i-day-primary-white !important;
        font-weight: 700;
        font-family: Inter, sans-serif;
        font-size: 20px;
      }
      &:hover {
        background: $i-day-primary-green;
      }
    }

    .ant-radio-wrapper {
      cursor: pointer;
      margin: 0;
      width: 100%;
      padding: 10px 20px;
      padding-left: 0;
      display: flex;
      align-items: center;

      .ant-radio {
        top: 0;
      }

      & > span {
        margin-right: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        letter-spacing: -0.005em;
        color: $i-day-grey-0;
      }
    }

    & .ant-radio-checked .ant-radio-inner,
    & .ant-radio:hover .ant-radio-inner {
      border-color: color.$primary-color;
    }
    & .ant-radio-inner:after {
      background-color: color.$primary-color;
    }
    & .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: 0 0 0 3px $i-day-primary-green-lightest;
      border-color: color.$primary-color;
    }

    .ant-radio-inner {
      width: 24px;
      height: 24px;
    }
  }
}
